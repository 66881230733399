import { createUseStyles } from "react-jss";

import { LightTheme } from "../../theme";

import { TextPropsWithColor } from "./text.types";

const colorByProps =
  (theme: LightTheme, defaultColor?: string) => (props: TextPropsWithColor) =>
    theme.colors[props.color] || defaultColor || theme.colors.white;

export const useStyle = createUseStyles((theme: LightTheme) => ({
  h1: {
    ...theme.typography.h1,
    color: colorByProps(theme),
  },
  h3: {
    ...theme.typography.h3,
    color: colorByProps(theme),
  },
  h4: {
    ...theme.typography.h4,
    color: colorByProps(theme),
  },
  h5: {
    ...theme.typography.h5,
    color: colorByProps(theme),
  },
  h6: {
    ...theme.typography.h5,
    color: colorByProps(theme),
  },
  title: {
    ...theme.typography.title,
    color: colorByProps(theme),
  },
  button: {
    ...theme.typography.button,
    color: colorByProps(theme),
  },
  body: {
    ...theme.typography.body,
    color: colorByProps(theme),
  },
  link: {
    ...theme.typography.link,
    color: colorByProps(theme),
  },
  description: {
    ...theme.typography.description,
    color: colorByProps(theme),
  },
}));
