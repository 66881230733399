import { createUseStyles } from "react-jss";

import { LightTheme } from "../../theme";

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.black,
    borderRadius: 16,
    height: 250,
    width: 250,
    padding: 16,
  },
  "@media screen and (max-width: 480px)": {
    container: {
      borderRadius: 16,
      height: 125,
      width: 125,
      padding: 8,
    },
  },
}));
