import React, { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { presetComponents } from "./text.presets";
import { useStyle } from "./text.styles";
import { TextProps } from "./text.types";

export const Text: FC<TextProps> = ({
  className = "",
  description,
  text = "",
  tx,
  values,
  preset = "body",
  innerHtml,
  color = "white",
  ...props
}) => {
  const classes = useStyle({ color, text });
  const Component = useMemo(() => presetComponents[preset], [preset]);
  const presetClassName = useMemo(() => classes[preset], [classes, preset]);

  if (!tx) {
    return (
      <Component className={`${className} ${presetClassName}`} {...props}>
        {text}
      </Component>
    );
  }

  return (
    <Component className={`${className} ${presetClassName}`} {...props}>
      <FormattedMessage
        id={tx}
        description={description}
        defaultMessage={text}
        values={values}
      />
    </Component>
  );
};
