import React, { FC } from "react";

import { Column } from "../column";
import { Text } from "../text";

import { OrderedListItem } from "./ordered-list-item";
import { useStyle } from "./ordered-list.styles";
import { OrderedListProps } from "./ordered-list.types";

export const OrderedList: FC<OrderedListProps> = ({
  className,
  data,
  title,
  titleTx,
}) => {
  const classes = useStyle();

  return (
    <Column
      alignItems="flex-start"
      justifyContent="flex-start"
      className={className}
    >
      <Text
        className={classes.title}
        color="white"
        preset="h3"
        text={title}
        tx={titleTx}
      />
      <Column
        className={classes.list}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {data.map((item, index) => (
          <OrderedListItem
            {...item}
            key={`order_list_item_${item.id || index}`}
            className={classes.item}
          />
        ))}
      </Column>
    </Column>
  );
};
