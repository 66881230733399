import { createUseStyles } from "react-jss";

export const useStyle = createUseStyles({
  title: {
    width: 900,
    textTransform: "uppercase",
  },
  description: {
    marginTop: 40,
    width: 720,
    textTransform: "capitalize",
  },
  button: {
    marginTop: 40,
    textTransform: "uppercase",
  },
  "@media screen and (max-width: 440px)": {
    title: {
      width: "calc(100% - 32px)",
    },
    description: {
      marginTop: 16,
      width: "calc(100% - 32px)",
    },
  },
});
