import { createUseStyles } from "react-jss";

export const useStyle = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "100%",
  },
});
