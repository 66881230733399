import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { DropdownStyleProps } from './dropdown.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  content: {
    background: theme.colors.buttonBlue,
    position: 'absolute',
    top: '100%',
    left: 0,
    zIndex: 2,
    width: ({ width }: DropdownStyleProps) => width
  },
  top: {
    justifyContent: 'flex-start'
  },
  row: {
    margin: '10px 5px'
  },
  text: {
    marginLeft: 28
  },
  arrow: {
    marginLeft: 28
  }
}))
