import React, { FC } from "react";

import { Column } from "../column";

import { useStyle } from "./pattern-background.styles";
import { PolygonBackgroundProps } from "./pattern-background.types";

export const PolygonBackground: FC<PolygonBackgroundProps> = ({ children }) => {
  const classes = useStyle();

  return (
    <Column fullWidth className={classes.container}>
      <Column className={classes.gradient}></Column>
      <Column className={classes.secondGradient}></Column>
      {children}
    </Column>
  );
};
