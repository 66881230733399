import { call, put } from "redux-saga/effects";

import { GetPartnersResults } from "../../services";
import { Api } from "../../services/api.types";

import { PartnerCreators, PartnerTypes } from "../actions";

export function* GetPartners(
  api: Api,
  action: PartnerTypes.GetPartnersActionRequest
) {
  const response: GetPartnersResults = yield call(
    api.partner.getPartners,
    action.payload
  );

  if (response.kind !== "ok") {
    yield put(
      PartnerCreators.getPartnersFailure({
        error: "Failure authentification",
      })
    );
  } else {
    yield put(PartnerCreators.getPartnersSuccess(response.data));
  }
}
