import { createReducer } from 'reduxsauce'

import { FooterActionTypes, FooterTypes } from '../../actions'

import { FooterState } from './footer.types'

/* ------------- Reducers ------------- */

const INITIAL_STATE: FooterState = {
  isLoaded: false,
  footerData: null,
  error: null
}

const getDataFooterRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoaded: false,
    error: null
  }
}

const getDataFooterSuccess = (
  state = INITIAL_STATE,
  action: FooterTypes.GetFooterActionSuccess
) => {
  const footerData = action.payload
  return {
    ...state,
    isLoaded: true,
    footerData
  }
}

const onError = (
  state = INITIAL_STATE,
  action: FooterTypes.GetFooterActionFailure
) => {
  return {
    ...state,
    isLoaded: true,
    error: action.payload.error
  }
}

/* ------------- Hookup Reducers To Types ------------- */

export const footerReducer = createReducer(INITIAL_STATE, {
  [FooterActionTypes.GET_FOOTER_REQUEST]: getDataFooterRequest,
  [FooterActionTypes.GET_FOOTER_SUCCESS]: getDataFooterSuccess,
  [FooterActionTypes.GET_FOOTER_FAILURE]: onError
})
