import { ApisauceInstance } from 'apisauce'
import camelcaseKeys from 'camelcase-keys'

import { getGeneralApiProblem } from '../api-problem'
import { GetHomeRequest, GetHomeResponse, GetHomeResults } from './home.types'

// our "constructor"
export const homeService = (api: ApisauceInstance) => {
  /**
   * Login user request from service
   * @param userData - Data about user to login auth
   */
  const getHome = async (options: GetHomeRequest): Promise<GetHomeResults> => {
    const response = await api.get<GetHomeResponse>('/home', {
      ...options.params,
      populate:
        'mainBanner.data.attributes, categories.image, topProducts.images'
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })
        return { kind: 'ok', data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  return {
    getHome
  }
}
