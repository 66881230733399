import React from "react";

import { BlockContainer, Button, Section, Text } from "../../components";

import { SectionIds } from "../helpers";

import { useStyle } from "./home.styles";

export const HomeBlock = () => {
  const classes = useStyle();

  const handleOnOpen = () => {
    window.open("mailTo:office@pgm.in.ua", "_self");
  };

  return (
    <Section id={SectionIds?.HOME_ID}>
      <BlockContainer patternBackground justifyContent="center">
        <Text
          className={classes.title}
          preset="h1"
          color="white"
          text="PREMIER GAMBLING MANAGEMENT"
          tx="home.title"
        />
        <Text
          className={classes.description}
          preset="h4"
          color="white"
          text="Group of partners providing holistic services in the gambling industry, from strategy to successfull set up & grand launch of online casinos and physical resorts"
          tx="home.description"
        />
        <Button
          className={classes.button}
          text="Write To Us"
          tx="home.button"
          onClick={handleOnOpen}
        />
      </BlockContainer>
    </Section>
  );
};
