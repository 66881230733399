import { takeLatest, all } from "redux-saga/effects";

import { config } from "../../config";
import API from "../../services/api";

import {
  HomeActionTypes,
  CategoryActionTypes,
  ProductActionTypes,
  FooterActionTypes,
  PartnerActionTypes,
} from "../actions";

import { GetHome } from "./home";
import { GetCategories, GetCategoryById } from "./category";

/* ------------- Types ------------- */

// import { StartupTypes } from '../startup-redux'

/* ------------- Sagas ------------- */

import { GetProductById, GetProducts } from "./product";
import { GetFooter } from "./footer";
import { GetPartners } from "./partner";

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.

const api = API.create(config.BASE_URL);

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    // Login api request

    takeLatest(HomeActionTypes.GET_HOME_REQUEST, GetHome, api),
    takeLatest(
      CategoryActionTypes.GET_CATEGORY_BY_ID_REQUEST,
      GetCategoryById,
      api
    ),
    takeLatest(CategoryActionTypes.GET_CATEGORIES_REQUEST, GetCategories, api),
    takeLatest(
      ProductActionTypes.GET_PRODUCT_BY_ID_REQUEST,
      GetProductById,
      api
    ),
    takeLatest(ProductActionTypes.GET_PRODUCTS_REQUEST, GetProducts, api),
    takeLatest(FooterActionTypes.GET_FOOTER_REQUEST, GetFooter, api),
    takeLatest(PartnerActionTypes.GET_PARTNERS_REQUEST, GetPartners, api),
  ]);
}

export type RootSaga = typeof root;
