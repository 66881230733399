import React from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'

import { SVGIconProps } from './icon.types'

export const World = ({ fill, size = 24, ...props }: SVGIconProps) => {
  const theme = useTheme<LightTheme>()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      {...props}
    >
      <path
        fill={fill || theme.colors.white}
        d="M12 22a9.694 9.694 0 0 1-3.882-.79 10.137 10.137 0 0 1-3.184-2.145 10.133 10.133 0 0 1-2.145-3.183A9.693 9.693 0 0 1 2 12c0-1.386.263-2.684.79-3.895a10.185 10.185 0 0 1 2.144-3.171 10.145 10.145 0 0 1 3.184-2.145A9.693 9.693 0 0 1 12 2a9.66 9.66 0 0 1 3.895.79 10.172 10.172 0 0 1 3.17 2.144 10.177 10.177 0 0 1 2.145 3.171c.527 1.21.79 2.509.79 3.895a9.693 9.693 0 0 1-.79 3.882 10.124 10.124 0 0 1-2.145 3.183 10.164 10.164 0 0 1-3.17 2.145A9.66 9.66 0 0 1 12 22Zm0-1.605c.544-.702.996-1.417 1.356-2.145.36-.728.662-1.522.907-2.382H9.737c.245.878.548 1.68.908 2.408s.811 1.434 1.355 2.119Zm-2.026-.29a11.648 11.648 0 0 1-1.092-1.973 12.42 12.42 0 0 1-.75-2.264h-3.58a8.448 8.448 0 0 0 2.237 2.75 7.404 7.404 0 0 0 3.185 1.487Zm4.052 0a7.403 7.403 0 0 0 3.184-1.486 8.447 8.447 0 0 0 2.237-2.75h-3.579a17.89 17.89 0 0 1-.79 2.275c-.315.746-.666 1.4-1.052 1.961ZM3.895 14.29h3.92c-.07-.386-.122-.767-.157-1.145a12.334 12.334 0 0 1 0-2.29c.035-.376.088-.757.158-1.143H3.895a7.25 7.25 0 0 0-.237 1.105 8.756 8.756 0 0 0 0 2.368c.053.386.131.755.237 1.106Zm5.5 0h5.21a12.251 12.251 0 0 0 .21-2.29 12.251 12.251 0 0 0-.21-2.288h-5.21a12.221 12.221 0 0 0-.21 2.29 12.221 12.221 0 0 0 .21 2.288Zm6.79 0h3.92c.105-.35.184-.719.237-1.105a8.761 8.761 0 0 0 0-2.368 7.253 7.253 0 0 0-.237-1.105h-3.92c.07.386.122.767.157 1.144a12.367 12.367 0 0 1 0 2.29c-.035.377-.088.758-.158 1.145Zm-.317-6.157h3.58a8.17 8.17 0 0 0-2.224-2.75c-.921-.729-1.987-1.233-3.198-1.514.404.614.764 1.285 1.08 2.013.315.728.57 1.479.762 2.25Zm-6.131 0h4.526a13.406 13.406 0 0 0-.934-2.435A12.646 12.646 0 0 0 12 3.605c-.509.65-.952 1.347-1.328 2.092-.378.746-.69 1.557-.935 2.435Zm-5.184 0h3.579c.193-.772.447-1.523.763-2.25.316-.729.675-1.4 1.079-2.014-1.228.281-2.299.785-3.21 1.513-.913.728-1.65 1.645-2.211 2.75Z"
      />
    </svg>
  )
}
