import React, { FC } from "react";
import { useTheme } from "react-jss";

import { LightTheme } from "../../../theme";

import { ColorDot } from "../../color-dot";
import { Row } from "../../row";
import { Text } from "../../text";

import { useStyle } from "./ordered-list-item.styles";
import { OrderedListItemProps } from "./ordered-list-item.types";

export const OrderedListItem: FC<OrderedListItemProps> = ({
  className,
  text,
  tx,
}) => {
  const theme = useTheme<LightTheme>();
  const classes = useStyle();

  return (
    <Row justifyContent="flex-start" className={className}>
      <ColorDot color={theme.colors.white} preset="big" />
      <Text
        className={classes.text}
        preset="h5"
        color="white"
        text={text}
        tx={tx}
      />
    </Row>
  );
};
