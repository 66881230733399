import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.backgroundBlue,
    padding: '100px 0 55px',
    zIndex: 9,
    position: 'fixed',
    inset: '84px 0 auto'
  },
  link: {
    textDecoration: 'none',
    marginTop: 15
  },
  linkText: {
    textTransform: 'uppercase'
  },
  linkContainer: {
    flexDirection: 'column'
  },

  burger: {
    flexDirection: 'column',
    minHeight: 310,
    justifyContent: 'start'
  },
  logo: {
    width: 162,
    height: 60,
    marginTop: 60
  },
  locale: {
    minWidth: 88,
    marginTop: 50
  },
  language: {
    marginTop: 50
  }
}))
