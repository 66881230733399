import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import Rehydration from "../services/rehydration";
import { config } from "../config";
import { Store } from "./index";
import { RootSaga } from "./sagas";

// creates the store
export default (rootReducer: Store, rootSaga: RootSaga) => {
  /* ------------- Saga Middleware ------------- */

  const sagaMiddleware = createSagaMiddleware();

  const createAppropriateStore = configureStore;

  const store = createAppropriateStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(sagaMiddleware),
    devTools: process.env.NODE_ENV !== "production",
  });

  // configure persistStore and check reducer version number
  if (config.REDUX_PERSIST.active) {
    Rehydration.updateReducers(store);
  }

  // kick off root saga
  const sagasManager = sagaMiddleware.run(rootSaga);

  return {
    store,
    sagasManager,
    sagaMiddleware,
  };
};
