import { ApisauceInstance } from "apisauce";
import camelcaseKeys from "camelcase-keys";

import { getGeneralApiProblem } from "../api-problem";

import {
  GetPartnersRequest,
  GetPartnersResponse,
  GetPartnersResults,
  PartnerApi,
} from "./partner.types";

// our "constructor"
export const partnerService = (api: ApisauceInstance): PartnerApi => {
  const getPartners = async (
    options: GetPartnersRequest
  ): Promise<GetPartnersResults> => {
    const response = await api.get<GetPartnersResponse>("/partners", {
      ...options.params,
      populate: "*",
    });

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) {
        return problem;
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true,
        });
        return { kind: "ok", data };
      }

      return { kind: "bad-data" };
    } catch {
      return { kind: "bad-data" };
    }
  };

  return {
    getPartners,
  };
};
