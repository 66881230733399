import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { buttonPresets } from './button.presets'
import { ButtonStyleProps } from './button.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    cursor: 'pointer',
    pointerEvents: ({ disabled }: ButtonStyleProps) => disabled && 'none',
    opacity: ({ disabled }: ButtonStyleProps) => disabled && '0.7'
  },
  text: {
    cursor: 'pointer',
    whiteSpace: 'nowrap'
  },
  ...buttonPresets(theme)
}))
