import { createReducer } from "reduxsauce";

import {
  ActionFailurePayload,
  ParamAction,
  PartnerActionTypes,
  PartnerTypes,
} from "../../actions";

import { PartnerState } from "./partner.types";

/* ------------- Reducers ------------- */

const INITIAL_STATE: PartnerState = {
  isLoaded: false,
  data: null,
  error: null,
};

const getDataRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoaded: false,
    error: null,
  };
};

const getDataPartnersSuccess = (
  state = INITIAL_STATE,
  action: PartnerTypes.GetPartnersActionSuccess
) => {
  const data = action.payload;
  return {
    ...state,
    isLoaded: true,
    data,
  };
};

const onError = (
  state = INITIAL_STATE,
  action: ParamAction<ActionFailurePayload>
) => {
  return {
    ...state,
    isLoaded: true,
    error: action.payload.error,
  };
};

/* ------------- Hookup Reducers To Types ------------- */

export const partnerReducer = createReducer(INITIAL_STATE, {
  [PartnerActionTypes.GET_PARTNERS_REQUEST]: getDataRequest,
  [PartnerActionTypes.GET_PARTNERS_SUCCESS]: getDataPartnersSuccess,
  [PartnerActionTypes.GET_PARTNERS_FAILURE]: onError,
});
