import { createUseStyles } from "react-jss";

import { pattern } from "../../assets";

export const useStyle = createUseStyles({
  container: {
    flex: 1,
    backgroundImage: `url(${pattern})`,
    backgroundSize: "10%",
    backgroundColor: "#1871C9",
    backgroundBlendMode: "hard-light",
  },
  gradient: {
    position: "absolute",
    inset: 0,
    background: "linear-gradient(0deg, #160E33 0%, rgba(22, 14, 51, 0) 31.25%)",
  },
  secondGradient: {
    position: "absolute",
    inset: 0,
    background:
      "linear-gradient(121.69deg, #160E33 28.68%, rgba(22, 14, 51, 0.3) 69.08%)",
  },
});
