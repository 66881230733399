import { createUseStyles } from "react-jss";

export const useStyle = createUseStyles({
  container: {
    paddingTop: 100,
    paddingBottom: 100,
  },
  title: {
    width: 900,
    textTransform: "capitalize",
  },
  description: {
    marginTop: 20,
    width: 720,
    textTransform: "capitalize",
  },
  cardContainer: {
    marginTop: 80,
  },
  card: {
    "&:not(:first-child)": {
      marginLeft: 24,
    },
  },
  "@media screen and (max-width: 440px)": {
    cardContainer: {
      marginTop: 24,
    },
    card: {
      width: "calc(100% - 32px)",
      height: 300,
      "&:not(:first-child)": {
        marginTop: 16,
        marginLeft: 0,
      },
    },
    title: {
      width: "calc(100% - 32px)",
    },
    description: {
      marginTop: 16,
      width: "calc(100% - 32px)",
    },
  },
});
