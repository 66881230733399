import React, { FC, useEffect } from "react";

import { SectionIds } from "../../blocks/helpers";

import { Column } from "../column";
import { Content } from "../content";
import { Email, Logo, Phone } from "../icon";
import { PolygonBackground } from "../polygon-background";
import { Row } from "../row";
import { Text } from "../text";

import { FooterList, FooterListItemData } from "./footer-list";
import { FooterListItem } from "./footer-list-item";
import { useStyle } from "./footer.styles";
import { useLocale } from "../../providers";
import { useDispatch, useSelector } from "react-redux";
import { FooterCreators, State } from "../../store";

export const COMPANY_DATA: FooterListItemData[] = [
  {
    link: `#${SectionIds?.HOME_ID}`,
    text: "Home",
    tx: "header.home",
  },
  {
    link: `#${SectionIds?.ABOUT_US_ID}`,
    text: "About us",
    tx: "about.title",
  },
  {
    link: `#${SectionIds?.PROPOSAL_ID}`,
    text: "Proposal",
    tx: "proposal.title",
  },
  {
    link: `#${SectionIds?.PARTNERS_ID}`,
    text: "Partners",
    tx: "partners.title",
  },
];

export type FooterProps = {};
export const Footer: FC<FooterProps> = () => {
  const classes = useStyle();
  const { currentLocale } = useLocale();
  const { isLoaded, data } = useSelector((state: State) => ({
    isLoaded: state.footer.isLoaded,
    data: state.footer.footerData,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      FooterCreators.getFooterRequest({ params: { locale: currentLocale } })
    );
  }, [currentLocale]);

  if (!isLoaded || !data) {
    return <></>;
  }

  const {
    companyTitle,
    socialTitle,
    contactUsTitle,
    facebook,
    instagram,
    linkedin,
    twitter,
    email,
    phone,
    copyright,
  } = data.data.attributes;

  return (
    <Column fullWidth>
      <PolygonBackground>
        <Content
          justifyContent="center"
          alignItems="flex-start"
          className={classes.container}
        >
          <Row
            wrap
            fullWidth
            className={classes.content}
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <a className={classes.logo} href="https://pgm.in.ua">
              <Logo />
            </a>
            <Row
              wrap
              className={classes.menu}
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Row
                wrap
                className={classes.menu}
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <FooterList title={companyTitle} data={COMPANY_DATA} />
                <Column alignItems="flex-start">
                  <Text color="white" preset="h5" text={socialTitle} />

                  {facebook && (
                    <FooterListItem
                      className={classes.item}
                      link={facebook}
                      text="Facebook"
                    />
                  )}
                  {instagram && (
                    <FooterListItem
                      className={classes.item}
                      link={instagram}
                      text="Instagram"
                    />
                  )}
                  {linkedin && (
                    <FooterListItem
                      className={classes.item}
                      link={linkedin}
                      text="Linkedin"
                    />
                  )}
                  {twitter && (
                    <FooterListItem
                      className={classes.item}
                      link={twitter}
                      text="Twitter"
                    />
                  )}
                </Column>
                <Column className={classes.contact} alignItems="flex-start">
                  <Text color="white" preset="h5" text={contactUsTitle} />
                  <Column
                    fullHeight
                    alignItems="flex-start"
                    justifyContent="space-between"
                  >
                    <Column alignItems="flex-start">
                      <FooterListItem
                        className={classes.item}
                        link={`mailTo:${email}`}
                        icon={<Email />}
                        text={email}
                      />
                      <FooterListItem
                        className={classes.item}
                        link={`tel:${phone}`}
                        icon={<Phone />}
                        text={phone}
                      />
                    </Column>
                    <Text
                      className={classes.copyright}
                      color="white"
                      preset="description"
                      text={copyright}
                    />
                  </Column>
                </Column>
              </Row>
            </Row>
          </Row>
        </Content>
      </PolygonBackground>
    </Column>
  );
};
