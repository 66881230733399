import React from "react";

import {
  AboutUsBlock,
  HomeBlock,
  PartnersBlock,
  ProposalBlock,
} from "../blocks";

// import { useStyle } from "./main.styles";

export const MainPage = () => {
  // const classes = useStyle();
  return (
    <>
      <HomeBlock />
      <AboutUsBlock />
      <ProposalBlock />
      <PartnersBlock />
    </>
  );
};
