import React, { MouseEventHandler, useMemo, useRef, useState } from "react";

import { burger } from "../../assets";
import { SectionIds } from "../../blocks";
import { useLocale } from "../../providers";
import { ServerLocale } from "../../services";

import { Column } from "../column";
import { Content } from "../content";
import { Logo } from "../icon";
import { Row } from "../row";
import { Text } from "../text";
import { Burger } from "../burger";
import { Dropdown, DropdownItem } from "../dropdown";

import { useStyle } from "./header.styles";

export type DropdownLocaleOption = {
  label: string;
  value: ServerLocale;
};

export const Header = () => {
  const ref = useRef(null);
  const { currentLocale, changeLocale } = useLocale();
  const classes = useStyle();
  const [open, changeOpen] = useState(false);

  const handleOnOpen: MouseEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    changeOpen(!open);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const locales: DropdownItem<ServerLocale>[] = [
    {
      value: ServerLocale.EN,
      name: "EN",
    },
    {
      value: ServerLocale.UK,
      name: "UA",
    },
  ];

  const CurrentLocale = useMemo(() => {
    return locales.find((c) => c.value === currentLocale);
  }, [currentLocale, locales]);

  const handleOnChangeLanguage = (nextValue: DropdownItem<ServerLocale>) => {
    if (changeLocale) {
      changeLocale(nextValue.value);
    }
  };

  return (
    <Column fullWidth className={classes.container}>
      <Content>
        <Row wrap fullWidth justifyContent="space-between">
          <a className={classes.link} href="https://pgm.in.ua">
            <Logo className={classes.logo} />
          </a>
          <img
            src={burger}
            className={classes.burger}
            alt="Burger icon"
            onClick={handleOnOpen}
          />
          {open && (
            <Burger
              ref={ref}
              locales={locales}
              currentLocale={CurrentLocale}
              onChangeLanguage={handleOnChangeLanguage}
            />
          )}
          <Row className={classes.linkContainer}>
            <a
              className={classes.link}
              href={`#${SectionIds?.HOME_ID}`}
              target="_self"
            >
              <Text
                className={classes.linkText}
                preset="body"
                text="Home"
                tx="header.home"
              />
            </a>
            <a
              className={classes.link}
              href={`#${SectionIds?.ABOUT_US_ID}`}
              target="_self"
            >
              <Text
                className={classes.linkText}
                preset="body"
                text="About us"
                tx="about.title"
              />
            </a>
            <a
              className={classes.link}
              href={`#${SectionIds?.PROPOSAL_ID}`}
              target="_self"
            >
              <Text
                className={classes.linkText}
                preset="body"
                text="Proposal"
                tx="proposal.title"
              />
            </a>
            <a
              className={classes.link}
              href={`#${SectionIds?.PARTNERS_ID}`}
              target="_self"
            >
              <Text
                className={classes.linkText}
                preset="body"
                text="Partners"
                tx="partners.title"
              />
            </a>
            <a
              className={classes.link}
              href={"https://shop.pgm.in.ua"}
              target="_self"
            >
              <Text
                className={classes.linkText}
                preset="body"
                text="Shop"
                tx="shop.title"
              />
            </a>
          </Row>
          <Dropdown
            className={classes.locale}
            value={CurrentLocale}
            data={locales}
            onChange={handleOnChangeLanguage}
          />
        </Row>
      </Content>
    </Column>
  );
};
