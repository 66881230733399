import React, { FC } from "react";

import { concatClassNames } from "../../utils";

import { Column } from "../column";

import { useStyle } from "./image-card.styles";
import { ImageCardProps } from "./image-card.types";

export const ImageCard: FC<ImageCardProps> = ({
  link,
  alt,
  className,
  src,
}) => {
  const classes = useStyle();

  return (
    <a href={link} target="_blank" rel="noreferrer">
      <Column className={concatClassNames(className, classes.container)}>
        <img src={src} width="100%" alt={alt} />
      </Column>
    </a>
  );
};
