import { createActions, createTypes } from 'reduxsauce'

import { FooterActions, FooterActionsTypes } from './footer.types'

export const footerStringTypes = createTypes(`
  GET_FOOTER_REQUEST
  GET_FOOTER_SUCCESS
  GET_FOOTER_FAILURE
`)

const { Types: FooterActionTypes, Creators: FooterCreators } = createActions<
  FooterActionsTypes,
  FooterActions
>({
  getFooterRequest: ['payload'],
  getFooterSuccess: ['payload'],
  getFooterFailure: ['payload']
})

export { FooterCreators, FooterActionTypes }
