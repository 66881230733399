import { call, put } from 'redux-saga/effects'

import { GetCategoriesResults, GetCategoryResults } from '../../services'
import { Api } from '../../services/api.types'

import { CategoryCreators, CategoryTypes } from '../actions'

export function* GetCategoryById(
  api: Api,
  action: CategoryTypes.GetCategoryActionRequest
) {
  const response: GetCategoryResults = yield call(
    api.category.getCategoryById,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      CategoryCreators.getCategoryByIdFailure({
        error: 'Failure authentification'
      })
    )
  } else {
    yield put(CategoryCreators.getCategoryByIdSuccess(response.data))
  }
}

export function* GetCategories(
  api: Api,
  action: CategoryTypes.GetCategoriesActionRequest
) {
  const response: GetCategoriesResults = yield call(
    api.category.getCategories,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      CategoryCreators.getCategoriesFailure({
        error: 'Failure authentification'
      })
    )
  } else {
    yield put(CategoryCreators.getCategoriesSuccess(response.data))
  }
}
