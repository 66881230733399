import { call, put } from 'redux-saga/effects'

import { GetProductByIdResults, GetProductsResults } from '../../services'
import { Api } from '../../services/api.types'

import { ProductCreators, ProductTypes } from '../actions'

export function* GetProductById(
  api: Api,
  action: ProductTypes.GetProductByIdActionRequest
) {
  const response: GetProductByIdResults = yield call(
    api.product.getProductById,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      ProductCreators.getProductByIdFailure({
        error: 'Failure authentification'
      })
    )
  } else {
    yield put(ProductCreators.getProductByIdSuccess(response.data))
  }
}

export function* GetProducts(
  api: Api,
  action: ProductTypes.GetProductsActionRequest
) {
  const response: GetProductsResults = yield call(
    api.product.getProducts,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      ProductCreators.getProductsFailure({
        error: 'Failure authentification'
      })
    )
  } else {
    yield put(ProductCreators.getProductsSuccess(response.data))
  }
}
