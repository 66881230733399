import { createReducer } from 'reduxsauce'

import { HomeActionTypes, HomeTypes } from '../../actions'

import { HomeState } from './home.types'

/* ------------- Reducers ------------- */

const INITIAL_STATE: HomeState = {
  isLoaded: false,
  homeData: null,
  error: null
}

const getDataHomeRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoaded: false,
    error: null
  }
}

const getDataHomeSuccess = (
  state = INITIAL_STATE,
  action: HomeTypes.GetHomeActionSuccess
) => {
  const homeData = action.payload
  return {
    ...state,
    isLoaded: true,
    homeData
  }
}

const onError = (
  state = INITIAL_STATE,
  action: HomeTypes.GetHomeActionFailure
) => {
  return {
    ...state,
    isLoaded: true,
    error: action.payload.error
  }
}

/* ------------- Hookup Reducers To Types ------------- */

export const homeReducer = createReducer(INITIAL_STATE, {
  [HomeActionTypes.GET_HOME_REQUEST]: getDataHomeRequest,
  [HomeActionTypes.GET_HOME_SUCCESS]: getDataHomeSuccess,
  [HomeActionTypes.GET_HOME_FAILURE]: onError
})
