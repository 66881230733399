import React, { FC } from "react";
import { concatClassNames } from "../../utils";

import { Column } from "../column";
import { Content } from "../content";
import { PolygonBackground } from "../polygon-background";

import { useStyle } from "./block-container.styles";
import { BlockContainerProps } from "./block-container.types";

export const BlockContainer: FC<BlockContainerProps> = ({
  className,
  innerClassName,
  children,
  patternBackground,
  justifyContent,
  alignItems,
}) => {
  const classes = useStyle();

  if (patternBackground) {
    return (
      <Column
        fullWidth
        className={concatClassNames(className, classes.container)}
      >
        <PolygonBackground>
          <Content
            className={innerClassName}
            alignItems={alignItems}
            justifyContent={justifyContent}
          >
            {children}
          </Content>
        </PolygonBackground>
      </Column>
    );
  }

  return (
    <Column
      fullWidth
      className={concatClassNames(className, classes.container)}
    >
      <Content alignItems={alignItems} justifyContent={justifyContent}>
        {children}
      </Content>
    </Column>
  );
};
