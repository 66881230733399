import React, { forwardRef } from "react";

import { Column } from "../column";
import { Content } from "../content";
import { Logo } from "../icon";
import { Row } from "../row";
import { Text } from "../text";
import { Dropdown } from "../dropdown";

import { useStyle } from "./burger.styles";
import { BurgerProps } from "./burger.types";
import { SectionIds } from "../../blocks";

export const Burger = forwardRef<HTMLDivElement, BurgerProps>(
  ({ locales, currentLocale, onChangeLanguage }, ref) => {
    const classes = useStyle();

    return (
      <Column fullWidth className={classes.container} ref={ref}>
        <Content>
          <Row wrap fullWidth className={classes.burger}>
            <Row className={classes.linkContainer}>
              <a
                className={classes.link}
                href={`#${SectionIds?.HOME_ID}`}
                target="_self"
              >
                <Text
                  className={classes.linkText}
                  preset="body"
                  text="Home"
                  tx="header.home"
                />
              </a>
              <a
                className={classes.link}
                href={`#${SectionIds?.ABOUT_US_ID}`}
                target="_self"
              >
                <Text
                  className={classes.linkText}
                  preset="body"
                  text="About us"
                  tx="about.title"
                />
              </a>
              <a
                className={classes.link}
                href={`#${SectionIds?.PROPOSAL_ID}`}
                target="_self"
              >
                <Text
                  className={classes.linkText}
                  preset="body"
                  text="Proposal"
                  tx="proposal.title"
                />
              </a>
              <a
                className={classes.link}
                href={`#${SectionIds?.PARTNERS_ID}`}
                target="_self"
              >
                <Text
                  className={classes.linkText}
                  preset="body"
                  text="Partners"
                  tx="partners.title"
                />
              </a>
              <a
                className={classes.link}
                href={"https://shop.pgm.in.ua"}
                target="_self"
              >
                <Text
                  className={classes.linkText}
                  preset="body"
                  text="Shop"
                  tx="shop.title"
                />
              </a>
              <Dropdown
                className={classes.locale}
                value={currentLocale}
                data={locales}
                onChange={onChangeLanguage}
                width={128}
              />
            </Row>
            <a className={classes.link} href="https://pgm.in.ua">
              <Logo className={classes.logo} />
            </a>
            <Row />
          </Row>
        </Content>
      </Column>
    );
  }
);
