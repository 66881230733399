import { createUseStyles } from 'react-jss'
import { RowProps } from './row.types'

export const useStyle = createUseStyles({
  row: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    alignItems: (props: RowProps) => props.alignItems || 'center',
    justifyContent: (props: RowProps) => props.justifyContent || 'center',
    width: (props: RowProps) => (props.fullWidth ? '100%' : 'auto'),
    flexWrap: (props: RowProps) => (props.wrap ? 'wrap' : 'nowrap')
  }
})
