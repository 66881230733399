import { createUseStyles } from "react-jss";

import { FooterListItemStyleProps } from "./footer-list-item.types";

export const useStyle = createUseStyles({
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    textTransform: "capitalize",
  },
  item: {
    marginLeft: ({ icon }: FooterListItemStyleProps) => (icon ? 16 : 0),
    textAlign: "left",
  },
});
