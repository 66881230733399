import React from "react";

import { BlockContainer, Card, Row, Section, Text } from "../../components";

import { SectionIds } from "../helpers";

import { useStyle } from "./proposal.styles";

export const ProposalBlock = () => {
  const classes = useStyle();

  return (
    <Section id={SectionIds?.PROPOSAL_ID}>
      <BlockContainer
        patternBackground
        innerClassName={classes.container}
        justifyContent="flex-start"
      >
        <Text
          className={classes.title}
          preset="h1"
          color="white"
          text="Proposal"
          tx="proposal.title"
        />
        <Text
          className={classes.description}
          preset="h4"
          color="white"
          text="THERE ARE 3 POSSIBLE SCENARIOS TO LAUNCH ONLINE CASINO"
          tx="proposal.description"
        />
        <Row
          wrap
          fullWidth
          className={classes.cardContainer}
          justifyContent="space-between"
        >
          <Card
            backgroundColor="bg"
            className={classes.card}
            description="Allows you to lease the software, gaming licence and payment processing system and operate your own casino under an established turnkey infrastructure. Flexible, fully customizable software solutions that allow you to control the entire process, from design to post-launch."
            descriptionTx="proposal.whiteSolution.description"
            textColor="black"
            title="WHITE LABEL SOLUTION SOFTWARE PROVIDER"
            titleTx="proposal.whiteSolution.title"
          />
          <Card
            backgroundColor="darkBlue"
            className={classes.card}
            description="Allows you to provide fully customised user experience. To ensure delivering the experiences their customer demand such as quick-click functionality and personalisation to recommend games based on a user’s game history, less over content interface etc."
            descriptionTx="proposal.proprietarySolution.description"
            textColor="white"
            title="PROPRIETARY PLATFORM FROM SCRATCH"
            titleTx="proposal.proprietarySolution.title"
          />
          <Card
            backgroundColor="black"
            className={classes.card}
            description="Allows to set up operations extremely fast, by adopting an existing engine, bonus system, customer database etc.
          Buying an existing platform allows to focus more on business & marketing goals & KPIs."
            descriptionTx="proposal.existingSolution.description"
            textColor="white"
            title="BUY AN EXISTING PLATFORM"
            titleTx="proposal.existingSolution.title"
          />
        </Row>
      </BlockContainer>
    </Section>
  );
};
