import React, { FC } from 'react'

import { ColorDotProps } from './color-dot.types'
import { useStyle } from './color-dot.styles'

export const ColorDot: FC<ColorDotProps> = ({
  preset,
  className = '',
  color,
  onClick
}) => {
  const classes = useStyle({ preset, color })

  return (
    <div className={`${classes.container} ${className}`} onClick={onClick} />
  )
}
