import { createUseStyles } from "react-jss";

import { LightTheme } from "../../theme";

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    minHeight: "calc(100vh - 120px)",
    backgroundColor: theme.colors.backgroundBlue,
    backgroundBlendMode: "darken",
  },
}));
