import { LightTheme } from "../../theme";
import { ButtonStyleProps } from "./button.types";

export const buttonPresets = (theme: LightTheme) => ({
  button: {
    backgroundColor: ({ color = "red" }: ButtonStyleProps) =>
      theme.colors[color],
    border: ({ color = "red" }: ButtonStyleProps) =>
      `1px solid ${theme.colors[color]}`,
    borderRadius: 40,
    padding: "16px 24px",
    "&:hover": {
      backgroundColor: `${theme.colors.red}bb`,
      borderColor: `${theme.colors.red}bb`,
    },
  },
});
