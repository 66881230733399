import { createActions, createTypes } from 'reduxsauce'

import { ProductActions, ProductActionsTypes } from './product.types'

export const productStringTypes = createTypes(`
    GET_PRODUCT_BY_ID_REQUEST
    GET_PRODUCT_BY_ID_SUCCESS
    GET_PRODUCT_BY_ID_FAILURE

    GET_PRODUCTS_REQUEST
    GET_PRODUCTS_SUCCESS
    GET_PRODUCTS_FAILURE
`)

const { Types: ProductActionTypes, Creators: ProductCreators } = createActions<
  ProductActionsTypes,
  ProductActions
>({
  getProductByIdRequest: ['payload'],
  getProductByIdSuccess: ['payload'],
  getProductByIdFailure: ['payload'],

  getProductsRequest: ['payload'],
  getProductsSuccess: ['payload'],
  getProductsFailure: ['payload']
})

export { ProductCreators, ProductActionTypes }
