import { createUseStyles } from "react-jss";
import { Route, Routes } from "react-router-dom";

import { MainPage } from "../page";
import { Column, Footer, Header } from "../components";

export const useStyle = createUseStyles({
  container: {
    flex: 1,
    minHeight: "100vh",
  },
});

export const MainRouter = () => {
  const classes = useStyle();

  return (
    <Column
      fullWidth
      fullHeight
      justifyContent="flex-start"
      className={classes.container}
    >
      <Header />
      <Routes>
        <Route index path="/" element={<MainPage />} />
      </Routes>
      <Footer />
    </Column>
  );
};
