import { createActions, createTypes } from 'reduxsauce'

import { StartUpActionsTypes } from './startup-redux.types'
/* ------------- Types and Action Creators ------------- */

export const startUpStringTypes = createTypes(`
  STARTUP
`)

const { Types, Creators } = createActions<StartUpActionsTypes>({
  startup: null
})

export const StartupTypes = Types
export default Creators
