import { createReducer } from 'reduxsauce'

import {
  ActionFailurePayload,
  CategoryActionTypes,
  CategoryTypes,
  ParamAction,
  ProductActionTypes,
  ProductTypes
} from '../../actions'

import { ProductState } from './product.types'

/* ------------- Reducers ------------- */

const INITIAL_STATE: ProductState = {
  isLoaded: false,
  categoryProducts: null,
  product: null,
  error: null
}

const getDataRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoaded: false,
    error: null
  }
}

const getDataCategoryByIdSuccess = (
  state = INITIAL_STATE,
  action: CategoryTypes.GetCategoryActionSuccess
) => {
  const categoryProducts = action.payload.data.attributes.products
  return {
    ...state,
    isLoaded: true,
    categoryProducts
  }
}

const getDataProductsSuccess = (
  state = INITIAL_STATE,
  action: ProductTypes.GetProductsActionSuccess
) => {
  const categoryProducts = action.payload
  return {
    ...state,
    isLoaded: true,
    categoryProducts
  }
}

const getDataProductByIdSuccess = (
  state = INITIAL_STATE,
  action: ProductTypes.GetProductByIdActionSuccess
) => {
  const product = action.payload
  return {
    ...state,
    isLoaded: true,
    product
  }
}

const onError = (
  state = INITIAL_STATE,
  action: ParamAction<ActionFailurePayload>
) => {
  return {
    ...state,
    isLoaded: true,
    error: action.payload.error
  }
}

/* ------------- Hookup Reducers To Types ------------- */

export const productReducer = createReducer(INITIAL_STATE, {
  [CategoryActionTypes.GET_CATEGORY_BY_ID_REQUEST]: getDataRequest,
  [CategoryActionTypes.GET_CATEGORY_BY_ID_SUCCESS]: getDataCategoryByIdSuccess,
  [CategoryActionTypes.GET_CATEGORY_BY_ID_FAILURE]: onError,

  [ProductActionTypes.GET_PRODUCTS_REQUEST]: getDataRequest,
  [ProductActionTypes.GET_PRODUCTS_SUCCESS]: getDataProductsSuccess,
  [ProductActionTypes.GET_PRODUCTS_FAILURE]: onError,

  [ProductActionTypes.GET_PRODUCT_BY_ID_REQUEST]: getDataRequest,
  [ProductActionTypes.GET_PRODUCT_BY_ID_SUCCESS]: getDataProductByIdSuccess,
  [ProductActionTypes.GET_PRODUCT_BY_ID_FAILURE]: onError
})
