import React, { SVGProps } from 'react'
import { Row } from '../row'

export const Logo = (props: SVGProps<SVGSVGElement>) => (
  <Row>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={60}
      viewBox="-10 -10 192 53"
      fill="none"
      {...props}
    >
      <path
        d="M110.199 2.35116V4.94435H108.717V2.97065L107.289 1.49829H102.063L100.635 2.97065V13.1475L102.063 14.6227H107.289L108.717 13.1475V9.3038H104.882V7.80551H110.199V13.7699L107.872 16.1181H101.46L99.1328 13.7699V2.35116L101.46 0H107.872L110.199 2.35116Z"
        fill="white"
      />
      <path
        d="M116.018 9.81091V16.1181H114.533V0H123.252L125.602 2.35116V7.46263L123.252 9.81091H120.034L125.557 15.3805L124.5 16.4639L117.932 9.81091H116.018ZM122.612 1.49829H116.018V8.31262H122.612L124.12 6.81721V2.9937L122.612 1.49829Z"
        fill="white"
      />
      <path
        d="M131.605 0H138.208L140.778 2.37421V13.7468L138.208 16.1181H131.605L129.035 13.7468V2.37421L131.605 0ZM139.296 2.9937L137.651 1.49829H132.151L130.497 2.9937V13.1244L132.139 14.6227H137.64L139.285 13.1244L139.296 2.9937Z"
        fill="white"
      />
      <path
        d="M147.235 16.1181L144.885 13.7699V0H146.37V13.1244L147.875 14.6227H152.964L154.469 13.1244V0H155.954V13.7699L153.604 16.1181H147.235Z"
        fill="white"
      />
      <path
        d="M162 9.97226V16.1181H160.518V0H169.236L171.587 2.35116V7.62399L169.236 9.97226H162ZM168.597 1.49829H162V8.47398H168.597L170.102 6.97857V2.9937L168.597 1.49829Z"
        fill="white"
      />
      <path
        d="M36.3125 32.121V24.9609H41.2416V26.4247H37.8632V27.9373H40.8247V29.3982H37.8632V32.121H36.3125Z"
        fill="white"
      />
      <path
        d="M49.1387 31.0347L48.0678 32.1239H45.3405L44.2695 31.0347V27.7356L45.3405 26.6465H48.0678L49.1387 27.7356V31.0347ZM45.9973 30.6631H47.3938L47.5823 30.47V28.3032L47.3938 28.1102H46.003L45.8145 28.3032V30.4585L45.9973 30.6631Z"
        fill="white"
      />
      <path
        d="M52.6641 31.0347V26.6465H54.2148V30.4585L54.4033 30.6515H55.4942L55.9425 30.1271V26.6465H57.4933V30.4181L57.8531 31.9913L56.6222 32.2795L56.4423 31.4929L55.894 32.1268H53.7436L52.6641 31.0347Z"
        fill="white"
      />
      <path
        d="M66.1715 27.7239V32.1208H64.6207V28.3001L64.4323 28.1071H63.3413L62.893 28.6315V32.1208H61.3423V28.3491L60.9824 26.7759L62.2133 26.4878L62.3932 27.2744L62.9415 26.6405H65.1005L66.1715 27.7239Z"
        fill="white"
      />
      <path
        d="M72.9158 32.121H70.7768L69.6973 31.0348V27.7241L70.7768 26.6465H72.4674L72.9672 27.0182V24.9609H74.5236V30.4182L74.8834 31.9914L73.6554 32.2795L73.4755 31.4929L72.9158 32.121ZM72.5245 30.6602L72.9757 30.1358V28.4819L72.476 28.1073H71.445L71.2565 28.3004V30.4585L71.445 30.6516L72.5245 30.6602Z"
        fill="white"
      />
      <path
        d="M81.4322 30.761L82.683 30.3576L83.0914 31.6571L81.6235 32.121H79.2846L78.2051 31.0347V27.7241L79.2846 26.6465H81.9491L83.0286 27.7241V29.1273L82.049 30.1041H79.7644V30.568L79.9557 30.761H81.4322ZM79.95 27.9056L79.7586 28.0987V28.8449H81.3379L81.4778 28.7038V28.0987L81.2894 27.9056H79.95Z"
        fill="white"
      />
      <path
        d="M89.6404 32.121H87.5014L86.4219 31.0348V27.7241L87.5014 26.6465H89.1892L89.6889 27.0182V24.9609H91.2396V30.4182L91.5995 31.9914L90.3686 32.2795L90.1887 31.4929L89.6404 32.121ZM89.2406 30.6602L89.6889 30.1358V28.4819L89.1892 28.1073H88.1611L87.9697 28.3004V30.4585L88.1611 30.6516L89.2406 30.6602Z"
        fill="white"
      />
      <path
        d="M100.944 28.2798L100.553 27.0783L102.015 26.5943L102.492 28.0666V32.1207H100.944V28.2798ZM100.924 25.799V25.0412L101.344 24.6177H102.052L102.512 25.0412V25.799L102.092 26.2226H101.332L100.924 25.799Z"
        fill="white"
      />
      <path
        d="M111.038 27.7239V32.1208H109.487V28.3001L109.299 28.1071H108.222L107.774 28.6315V32.1208H106.223V28.3491L105.863 26.7759L107.094 26.4878L107.274 27.2744L107.825 26.6405H109.973L111.038 27.7239Z"
        fill="white"
      />
      <path
        d="M121.674 32.121L120.395 30.8417V24.9609H121.931V30.2568L122.331 30.6602H123.881L124.281 30.2568V24.9609H125.829V30.8417L124.55 32.121H121.674Z"
        fill="white"
      />
      <path
        d="M129.558 26.4847L129.17 25.2717L130.629 24.7876L131.109 26.26V28.4786L133.157 26.4328L134.216 27.522L132.503 29.2508L134.216 31.3484L133.048 32.3251L131.389 30.3082L131.103 30.5791V32.1321H129.553L129.558 26.4847Z"
        fill="white"
      />
      <path
        d="M139.406 28.1071L138.955 28.6315V32.1208H137.407V28.3491L137.047 26.7759L138.275 26.4878L138.455 27.2744L139.006 26.6405H140.885L142.045 27.793L141.014 28.8505L140.254 28.1042L139.406 28.1071Z"
        fill="white"
      />
      <path
        d="M149.399 30.418L149.759 31.9912L148.528 32.2793L148.349 31.4927L147.8 32.1266H145.65L144.57 31.0346V29.7236L145.65 28.6431H147.849V28.1907L147.66 27.9977H146.281L145.03 28.4011L144.622 27.1016L146.09 26.6377H148.32L149.399 27.7153V30.418ZM147.849 30.3373V29.9138H146.309L146.121 30.1039V30.6802L146.309 30.8732H147.4L147.849 30.3373Z"
        fill="white"
      />
      <path
        d="M153.278 28.2798L152.887 27.0783L154.346 26.5943L154.826 28.0666V32.1207H153.278V28.2798ZM153.258 25.799V25.0412L153.678 24.6177H154.386L154.857 25.0412V25.799L154.437 26.2226H153.678L153.258 25.799Z"
        fill="white"
      />
      <path
        d="M163.374 27.7239V32.1208H161.823V28.3001L161.635 28.1071H160.544L160.095 28.6315V32.1208H158.545V28.3491L158.199 26.7759L159.43 26.4878L159.61 27.2744L160.158 26.6405H162.309L163.374 27.7239Z"
        fill="white"
      />
      <path
        d="M170.13 30.761L171.378 30.3576L171.79 31.6571L170.319 32.121H167.98L166.9 31.0347V27.7241L167.98 26.6465H170.639L171.718 27.7241V29.1273L170.739 30.1041H168.454V30.568L168.642 30.761H170.13ZM168.64 27.9056L168.451 28.0987V28.8449H170.03L170.17 28.7038V28.0987L169.979 27.9056H168.64Z"
        fill="white"
      />
      <path
        d="M48.5354 0.00830078H36.3867V3.81453V7.56026V11.3694V16.1091H40.1621V11.3751H48.5354L50.5859 7.78788V3.6042H50.5716H50.5859L48.5354 0.00830078ZM40.1507 3.81453H46.3564L46.8133 4.61266V6.76789L46.3564 7.56602H40.1621L40.1507 3.81453Z"
        fill="white"
      />
      <path
        d="M85.7137 0.00537109V0.00825303L85.7108 0.00537109L80.913 8.39003L76.1152 0.00537109V0.00825303V0.00537109H72.3398V16.1149H76.1152V7.6236L79.4423 13.4381L79.448 13.4352V13.4381H82.3866V13.4323L85.7137 7.61783V16.1149H89.4862V0.00537109H85.7137Z"
        fill="white"
      />
      <path
        d="M68.5875 12.5276V9.96611V8.06156V6.157H61.091V9.96611H64.8121V11.5076L64.3552 12.3057H58.6093L58.1523 11.5076V4.61549L58.6093 3.81448H66.6998V0.00537109H56.4274V0.00825303L54.377 3.59262L54.3941 3.60415H54.377V12.5564H54.3912L56.4303 16.1149L56.4531 16.1033V16.1149H66.537V16.112L68.5875 12.5305V12.5276Z"
        fill="white"
      />
      <path
        d="M15.4968 27.092H9.29709L6.19727 32.5101H12.3969H18.5966L15.4968 27.092Z"
        fill="#FCD430"
      />
      <path
        d="M24.7948 21.6763L21.6969 27.0923L18.597 21.6763H12.3974H6.19772L3.0979 27.0923L0 21.6763L3.0979 27.0923H9.29755L12.3974 21.6763L15.4972 27.0923H21.6969L24.7948 21.6763Z"
        fill="#2684D1"
      />
      <path
        d="M12.3987 21.6763L9.29883 27.0923H15.4985L12.3987 21.6763Z"
        fill="#FCD430"
      />
      <path
        d="M12.3974 0.00976562L6.19772 10.8439L0 21.676H6.19772L9.29755 16.26L12.3974 10.8439V0.00976562Z"
        fill="#1871C9"
      />
      <path
        d="M6.19772 21.6763H0L3.09789 27.0923L6.19772 21.6763Z"
        fill="#0F5EB9"
      />
      <path
        d="M12.3965 0.00976562V10.8439L15.4963 16.26L18.5961 21.676H24.7939L18.5961 10.8439L12.3965 0.00976562Z"
        fill="#2684D1"
      />
      <path
        d="M24.7934 21.6763H18.5957L21.6955 27.0923L24.7934 21.6763Z"
        fill="#0F5EB9"
      />
    </svg>
  </Row>
)
