import { ApisauceInstance } from 'apisauce'
import camelcaseKeys from 'camelcase-keys'

import { getGeneralApiProblem } from '../api-problem'
import {
  GetProductByIdRequest,
  GetProductByIdResponse,
  GetProductByIdResults,
  GetProductsRequest,
  GetProductsResponse,
  GetProductsResults,
  ProductApi
} from './product.types'

// our "constructor"
export const productService = (api: ApisauceInstance): ProductApi => {
  /**
   * Login user request from service
   * @param userData - Data about user to login auth
   */
  const getProductById = async (
    options: GetProductByIdRequest
  ): Promise<GetProductByIdResults> => {
    const { id } = options.query
    const response = await api.get<GetProductByIdResponse>(`/products/${id}`, {
      ...options.params,
      populate: '*'
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })
        return { kind: 'ok', data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const getProducts = async (
    options: GetProductsRequest
  ): Promise<GetProductsResults> => {
    const response = await api.get<GetProductsResponse>('/products', {
      ...options.params,
      populate: '*'
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })
        return { kind: 'ok', data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  return {
    getProductById,
    getProducts
  }
}
