import * as React from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'

import { SVGIconProps } from './icon.types'

export const ArrowDown = ({ size = 24, ...props }: SVGIconProps) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        stroke={props.fill || theme.colors.white}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m4 8 8 8 8-8"
      />
    </svg>
  )
}
