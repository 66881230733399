import React from "react";
import { ThemeProvider } from "react-jss";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { LocaleProvider } from "./providers";
import { lightTheme } from "./theme";
import createStore from "./store";
import { MainRouter } from "./routes";

import "./App.css";

const store = createStore();

function App() {
  return (
    <BrowserRouter>
      <LocaleProvider>
        <Provider store={store}>
          <ThemeProvider theme={lightTheme}>
            <MainRouter />
          </ThemeProvider>
        </Provider>
      </LocaleProvider>
    </BrowserRouter>
  );
}

export default App;
