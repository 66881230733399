import React, { FC } from "react";

import { concatClassNames } from "../../utils";

import { Column } from "../column";
import { Text } from "../text";

import { useStyle } from "./card.styles";
import { CardProps } from "./card.types";

export const Card: FC<CardProps> = ({
  className,
  backgroundColor,
  description,
  descriptionTx,
  textColor = "white",
  title,
  titleTx,
}) => {
  const classes = useStyle({ backgroundColor });

  return (
    <Column
      className={concatClassNames(className, classes.container)}
      justifyContent="flex-start"
    >
      <Text
        className={classes.title}
        color={textColor}
        preset="h3"
        text={title}
        tx={titleTx}
      />
      <Text
        className={classes.description}
        color={textColor}
        preset="h5"
        text={description}
        tx={descriptionTx}
      />
    </Column>
  );
};
