import { createUseStyles } from "react-jss";

export const useStyle = createUseStyles({
  container: {
    paddingTop: 100,
    paddingBottom: 100,
    minHeight: "calc(100vh - 320px)",
  },
  title: {
    textTransform: "capitalize",
    width: 920,
  },
  cardContainer: {
    marginTop: 56,
  },
  card: {
    gap: 24,
    marginTop: 24,
  },
  "@media screen and (max-width: 440px)": {
    cardContainer: {
      marginTop: 24,
      justifyContent: "space-between",
    },
    card: {
      marginTop: 16,
      width: "calc(50vw - 48px)",
      height: "calc(50vw - 48px)",
    },
    title: {
      width: "calc(100% - 32px)",
    },
  },
});
