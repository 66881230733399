import { ApisauceInstance } from 'apisauce'
import camelcaseKeys from 'camelcase-keys'

import { getGeneralApiProblem } from '../api-problem'
import {
  GetCategoriesRequest,
  GetCategoriesResponse,
  GetCategoriesResults,
  GetCategoryRequest,
  GetCategoryResponse,
  GetCategoryResults
} from './category.types'

// our "constructor"
export const categoryService = (api: ApisauceInstance) => {
  /**
   * Login user request from service
   * @param userData - Data about user to login auth
   */
  const getCategoryById = async (
    options: GetCategoryRequest
  ): Promise<GetCategoryResults> => {
    const { id } = options.query
    const response = await api.get<GetCategoryResponse>(`/categories/${id}`, {
      ...options.params,
      'populate[products][populate]': '*'
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })
        return { kind: 'ok', data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const getCategories = async (
    options: GetCategoriesRequest
  ): Promise<GetCategoriesResults> => {
    const response = await api.get<GetCategoriesResponse>('/categories', {
      ...options.params,
      populate: '*'
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })
        return { kind: 'ok', data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  return {
    getCategoryById,
    getCategories
  }
}
