import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  BlockContainer,
  ImageCard,
  Row,
  Section,
  Text,
} from "../../components";
import { PartnerCreators, State } from "../../store";
import { useLocale } from "../../providers";

import { SectionIds } from "../helpers";

import { useStyle } from "./partners.styles";

// import { PARTNER_IMAGES } from "../../assets";
// const PARTNERS: Partner[] = [
//   {
//     id: "1",
//     name: "Plasma 8",
//     avatar: PARTNER_IMAGES.plasma8,
//     link: "https://plasma8.com",
//   },
//   {
//     id: "2",
//     name: "Casino Life",
//     avatar: PARTNER_IMAGES.casinoLife,
//     link: "https://www.casinolifemagazine.com",
//   },
//   {
//     id: "3",
//     name: "Gaming Post",
//     avatar: PARTNER_IMAGES.gamingPost,
//     link: "https://gamingpost.net",
//   },
//   {
//     id: "4",
//     name: "Game Bridge",
//     avatar: PARTNER_IMAGES.gameBridge,
//     link: "https://gamebridge.ua",
//   },
//   {
//     id: "5",
//     name: "Game Insider",
//     avatar: PARTNER_IMAGES.gamingInsider,
//     link: "https://t.me/gaminginsider",
//   },
//   {
//     id: "6",
//     name: "Game Master",
//     avatar: PARTNER_IMAGES.gameMaster,
//     link: "https://www.gamemaster.com.ua",
//   },
//   {
//     id: "7",
//     name: "Azart",
//     avatar: PARTNER_IMAGES.azart,
//     link: "https://azart.info/ru/новости",
//   },
//   {
//     id: "8",
//     name: "Batumi Gaming Fest",
//     avatar: PARTNER_IMAGES.bgf,
//     link: "https://bgf.ge",
//   },
// ];

export const PartnersBlock = () => {
  const dispatch = useDispatch();
  const { currentLocale } = useLocale();
  const { isLoaded, data } = useSelector((state: State) => state.partner);

  useEffect(() => {
    dispatch(
      PartnerCreators.getPartnersRequest({ params: { locale: currentLocale } })
    );
  }, []);

  const classes = useStyle();

  if (!isLoaded || !data) {
    return <></>;
  }

  return (
    <Section id={SectionIds?.PARTNERS_ID}>
      <BlockContainer className={classes.container} justifyContent="flex-start">
        <Text
          className={classes.title}
          preset="h1"
          color="white"
          text="Partners"
          tx="partners.title"
        />
        <Row
          wrap
          className={classes.cardContainer}
          justifyContent="space-between"
        >
          {data.data.map((partner) => {
            const { id, attributes } = partner;
            const { link, logo, name } = attributes;
            return (
              <ImageCard
                key={`partner_${id}`}
                className={classes.card}
                link={link}
                alt={name}
                src={logo.data.attributes.url}
              />
            );
          })}
        </Row>
      </BlockContainer>
    </Section>
  );
};
