import { call, put } from 'redux-saga/effects'

import { GetHomeResults } from '../../services'
import { Api } from '../../services/api.types'

import { HomeCreators, HomeTypes } from '../actions'

export function* GetHome(api: Api, action: HomeTypes.GetHomeActionRequest) {
  const response: GetHomeResults = yield call(api.home.getHome, action.payload)

  if (response.kind !== 'ok') {
    yield put(
      HomeCreators.getHomeFailure({
        error: 'Failure authentification'
      })
    )
  } else {
    yield put(HomeCreators.getHomeSuccess(response.data))
  }
}
