import { createReducer } from 'reduxsauce'

import {
  CategoryActionTypes,
  CategoryTypes,
  ActionFailurePayload,
  ParamAction
} from '../../actions'

import { CategoryState } from './category.types'

/* ------------- Reducers ------------- */

const INITIAL_STATE: CategoryState = {
  categories: null,
  isLoaded: false,
  data: null,
  error: null
}

const getDataCategoryRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    data: null,
    isLoaded: false,
    error: null
  }
}

const getDataCategorySuccess = (
  state = INITIAL_STATE,
  action: CategoryTypes.GetCategoryActionSuccess
) => {
  const data = action.payload.data
  const category = {
    ...data,
    attributes: {
      ...data.attributes
    }
  }
  return {
    ...state,
    isLoaded: true,
    data: category
  }
}

const getDataCategoriesRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    categories: null,
    isLoaded: false,
    error: null
  }
}

const getDataCategoriesSuccess = (
  state = INITIAL_STATE,
  action: CategoryTypes.GetCategoriesActionSuccess
) => {
  const data = action.payload
  return {
    ...state,
    isLoaded: true,
    categories: data
  }
}

const onError = (
  state = INITIAL_STATE,
  action: ParamAction<ActionFailurePayload>
) => {
  return {
    ...state,
    isLoaded: true,
    error: action.payload.error
  }
}

/* ------------- Hookup Reducers To Types ------------- */

export const categoryReducer = createReducer(INITIAL_STATE, {
  [CategoryActionTypes.GET_CATEGORY_BY_ID_REQUEST]: getDataCategoryRequest,
  [CategoryActionTypes.GET_CATEGORY_BY_ID_SUCCESS]: getDataCategorySuccess,
  [CategoryActionTypes.GET_CATEGORY_BY_ID_FAILURE]: onError,

  [CategoryActionTypes.GET_CATEGORIES_REQUEST]: getDataCategoriesRequest,
  [CategoryActionTypes.GET_CATEGORIES_SUCCESS]: getDataCategoriesSuccess,
  [CategoryActionTypes.GET_CATEGORIES_FAILURE]: onError
})
