import { createActions, createTypes } from "reduxsauce";

import { PartnerActions, PartnerActionsTypes } from "./partner.types";

export const partnerStringTypes = createTypes(`
  GET_PARTNERS_REQUEST
  GET_PARTNERS_SUCCESS
  GET_PARTNERS_FAILURE
`);

const { Types: PartnerActionTypes, Creators: PartnerCreators } = createActions<
  PartnerActionsTypes,
  PartnerActions
>({
  getPartnersRequest: ["payload"],
  getPartnersSuccess: ["payload"],
  getPartnersFailure: ["payload"],
});

export { PartnerCreators, PartnerActionTypes };
