import { call, put } from 'redux-saga/effects'

import { GetFooterResults } from '../../services'
import { Api } from '../../services/api.types'

import { FooterCreators, FooterTypes } from '../actions'

export function* GetFooter(
  api: Api,
  action: FooterTypes.GetFooterActionRequest
) {
  const response: GetFooterResults = yield call(
    api.footer.getFooter,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      FooterCreators.getFooterFailure({
        error: 'Failure authentification'
      })
    )
  } else {
    yield put(FooterCreators.getFooterSuccess(response.data))
  }
}
