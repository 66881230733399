import React from "react";

import { roulette } from "../../assets";
import {
  BlockContainer,
  Row,
  Text,
  OrderedList,
  OrderedListItemData,
  Section,
} from "../../components";

import { SectionIds } from "../helpers";

import { useStyle } from "./about-us.styles";

export const TECHNOLOGY_DATA: OrderedListItemData[] = [
  {
    id: "1",
    text: "PAYMENT SYSTEMS INTEGRATION",
    tx: "about.technology.first",
  },
  {
    id: "2",
    text: "PAYMENT SYSTEMS INTEGRATION",
    tx: "about.technology.second",
  },
  {
    id: "3",
    text: "PAYMENT SYSTEMS INTEGRATION",
    tx: "about.technology.third",
  },
  {
    id: "4",
    text: "PAYMENT SYSTEMS INTEGRATION",
    tx: "about.technology.fourth",
  },
  {
    id: "5",
    text: "PAYMENT SYSTEMS INTEGRATION",
    tx: "about.technology.fivth",
  },
];

export const TOOLS_DATA: OrderedListItemData[] = [
  {
    id: "6",
    text: "iGAMING PROVIDERS",
    tx: "about.tools.first",
  },
  {
    id: "7",
    text: "CONTENT PRODUCTION & INTEGRATION",
    tx: "about.tools.second",
  },
  {
    id: "8",
    text: "OPERATIONAL MANAGEMENT",
    tx: "about.tools.third",
  },
  {
    id: "9",
    text: "STAFFING & TRAINING",
    tx: "about.tools.fourth",
  },
];

export const MARKETING_DATA: OrderedListItemData[] = [
  {
    id: "10",
    text: "PROMOTION & PR",
    tx: "about.marketing.first",
  },
  {
    id: "11",
    text: "JUNKET TOUR MANAGEMENT — VIP",
    tx: "about.marketing.second",
  },
  {
    id: "12",
    text: "LOYALTY PROGRAMS",
    tx: "about.marketing.third",
  },
  {
    id: "13",
    text: "CUSTOMER SERVICE",
    tx: "about.marketing.fourth",
  },
];

export const AboutUsBlock = () => {
  const classes = useStyle();

  return (
    <Section id={SectionIds?.ABOUT_US_ID}>
      <BlockContainer className={classes.block} justifyContent="flex-start">
        <Text
          className={classes.title}
          preset="h1"
          color="white"
          text="About us"
          tx="about.title"
        />
        <Text
          className={classes.description}
          preset="h4"
          color="white"
          text="15 years of experience in building Gambling Frameworks"
          tx="about.description"
        />

        <img
          className={classes.banner}
          src={roulette}
          width="100%"
          alt="Roulette wheel"
        />

        <Row
          wrap
          fullWidth
          className={classes.info}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <OrderedList
            className={classes.orderedList}
            title="TECHNOLOGY"
            titleTx="about.technology.title"
            data={TECHNOLOGY_DATA}
          />
          <OrderedList
            className={classes.orderedList}
            title="TOOLS"
            titleTx="about.tools.title"
            data={TOOLS_DATA}
          />
          <OrderedList
            className={classes.orderedList}
            title="MARKETING"
            titleTx="about.marketing.title"
            data={MARKETING_DATA}
          />
        </Row>
      </BlockContainer>
    </Section>
  );
};
