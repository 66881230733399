import { createActions, createTypes } from 'reduxsauce'

import { HomeActions, HomeActionsTypes } from './home.types'

export const homeStringTypes = createTypes(`
  GET_HOME_REQUEST
  GET_HOME_SUCCESS
  GET_HOME_FAILURE
`)

const { Types: HomeActionTypes, Creators: HomeCreators } = createActions<
  HomeActionsTypes,
  HomeActions
>({
  getHomeRequest: ['payload'],
  getHomeSuccess: ['payload'],
  getHomeFailure: ['payload']
})

export { HomeCreators, HomeActionTypes }
