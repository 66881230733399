import React, { forwardRef } from 'react'

import { useStyle } from './row.styles'
import { RowProps } from './row.types'

export const Row = forwardRef<HTMLDivElement, RowProps>(
  (
    {
      alignItems,
      justifyContent,
      fullWidth,
      className = '',
      children,
      wrap,
      ...props
    },
    ref
  ) => {
    const classes = useStyle({
      alignItems,
      justifyContent,
      fullWidth,
      className,
      children,
      wrap,
      ...props
    })

    return (
      <div {...props} className={`${className || ''} ${classes.row}`} ref={ref}>
        {children}
      </div>
    )
  }
)
