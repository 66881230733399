import { createUseStyles } from "react-jss";

export const useStyle = createUseStyles({
  container: {
    minHeight: 300,
  },
  item: {
    marginTop: 16,
    textTransform: "none",
  },
  contact: {
    alignSelf: "stretch",
  },
  logo: {
    flex: 4,
  },
  menu: {
    flex: 7,
  },
  content: {},
  copyright: {
    marginTop: 16,
  },
  "@media screen and (max-width: 440px)": {
    container: {
      padding: "12px 0",
    },
    logo: {
      justifyContent: "center",
      width: 162,
      height: 60,
      marginLeft: 32,
    },
    menu: {
      padding: "0px 24px",
      justifyContent: "space-between",
      "& > div": {
        marginTop: 24,
      },
    },
    content: {
      justifyContent: "space-around",
      flexDirection: "column",
    },
  },
});
