// a library to wrap and simplify api calls

import apisauce from "apisauce";
import { config } from "../config";

import { authService } from "./auth";
import { categoryService } from "./caregory";
import { homeService } from "./home";
import { productService } from "./product";
import { footerService } from "./footer";
import { partnerService } from "./partner";

// our "constructor"
const create = (baseURL = "/") => {
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      "Cache-Control": "no-cache",
      Authorization: `Bearer ${config.API_TOKEN}`,
    },
    // 10 second timeout...
    timeout: 10000,
  });
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //

  const getRoot = () => api.get("");

  const setHeader = (key: string, prop: string) => {
    const headers = { ...api.headers, [key]: prop };
    api.setHeaders(headers);
  };
  const setToken = (token: string) => {
    if (token) {
      api.setHeader("Authorization", `JWT ${token}`);
    } else {
      api.setHeader("Authorization", "");
    }
  };

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2
    getRoot,
    setHeader,
    setToken,
    auth: authService(api),
    home: homeService(api),
    partner: partnerService(api),
    category: categoryService(api),
    product: productService(api),
    footer: footerService(api),
  };
};

// let's return back our create method as the default.
export default {
  create,
};
