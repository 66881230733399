import { createActions, createTypes } from 'reduxsauce'

import { CategoryActions, CategoryActionsTypes } from './category.types'

export const caregoryStringTypes = createTypes(`
    GET_CATEGORY_BY_ID_REQUEST
    GET_CATEGORY_BY_ID_SUCCESS
    GET_CATEGORY_BY_ID_FAILURE

    GET_CATEGORIES_REQUEST
    GET_CATEGORIES_SUCCESS
    GET_CATEGORIES_FAILURE
`)

const { Types: CategoryActionTypes, Creators: CategoryCreators } =
  createActions<CategoryActionsTypes, CategoryActions>({
    getCategoryByIdRequest: ['payload'],
    getCategoryByIdSuccess: ['payload'],
    getCategoryByIdFailure: ['payload'],

    getCategoriesRequest: ['payload'],
    getCategoriesSuccess: ['payload'],
    getCategoriesFailure: ['payload']
  })

export { CategoryCreators, CategoryActionTypes }
