export type ClassName = string | undefined

export const concatClassNames = (...arr: ClassName[]) =>
  arr.reduce((acc, className) => {
    if (acc && className) {
      return `${acc} ${className}`
    }

    if (className) {
      return className
    }

    return acc
  }, '')
