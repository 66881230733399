import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

import configureStore from "./store";
import rootSaga from "./sagas";
import { config } from "../config";
import * as reducers from "./reducers";

/* ------------- Assemble The Reducers ------------- */
export const storeReducers = combineReducers({
  app: reducers.appReducer,
  home: reducers.homeReducer,
  category: reducers.categoryReducer,
  product: reducers.productReducer,
  partner: reducers.partnerReducer,
  footer: reducers.footerReducer,
});

export type Store = typeof storeReducers;

export default () => {
  let finalReducers: Store = storeReducers;
  // If rehydration is on use persistReducer otherwise default combineReducers
  if (config.REDUX_PERSIST.active) {
    const persistConfig = config.REDUX_PERSIST.storeConfig;
    // @ts-ignore
    finalReducers = persistReducer(persistConfig, storeReducers);
  }

  let { store, sagasManager, sagaMiddleware } = configureStore(
    finalReducers,
    rootSaga
  );

  // @ts-ignore
  if (module.hot) {
    // @ts-ignore
    module.hot.accept(() => {
      const nextRootReducer = require("./").reducers;
      store.replaceReducer(nextRootReducer);

      const newYieldedSagas = require("./sagas").default;
      sagasManager.cancel();
      // @ts-ignore
      sagasManager.done.then(() => {
        // @ts-ignore
        sagasManager = sagaMiddleware(newYieldedSagas);
      });
    });
  }

  return store;
};

export * from "./actions";
export * from "./store.types";
