import { createUseStyles } from "react-jss";

import { LightTheme } from "../../theme";

import { CardStyleProps } from "./card.types";

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: ({ backgroundColor }: CardStyleProps) =>
      theme.colors[backgroundColor],
    width: 328,
    minHeight: 380,
    padding: 24,
    borderRadius: 16,
  },
  title: {
    textTransform: "uppercase",
  },
  description: {
    marginTop: 24,
  },
}));
