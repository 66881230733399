import React from "react";
import { TextComponentProps } from "./text.types";

export const spanComponent = ({ children, ...props }: TextComponentProps) => (
  <span {...props}>{children}</span>
);

export const presetComponents = {
  h1: spanComponent,
  h3: spanComponent,
  h4: spanComponent,
  h5: spanComponent,
  h6: spanComponent,
  title: spanComponent,
  button: spanComponent,
  body: spanComponent,
  link: spanComponent,
  description: spanComponent,
};
