import { createReducer } from 'reduxsauce'
import { AppTypes, AppActionTypes } from '../../actions'

/* ------------- Reducers ------------- */

const INITIAL_STATE = {
  isLoaded: false
}

export const initApp = (state = INITIAL_STATE, action: AppTypes.LoadAction) => {
  const { isLoaded } = action
  return {
    ...state,
    isLoaded
  }
}

/* ------------- Hookup Reducers To Types ------------- */

export const appReducer = createReducer(INITIAL_STATE, {
  [AppActionTypes.IS_LOADED]: initApp
})
